@import '../../../_metronic/assets/sass/components/variables.custom';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.MuiFormControl-root {
  .MuiInputLabel-root {
    font-size: 1.7rem !important;
    color: #333232 !important;
    font-weight: 450 !important;
    .MuiInputLabel-asterisk {
      color: red;
    }
  }
  .MuiOutlinedInput-root {
    font-size: 1.1rem !important;
    fieldset legend {
      font-size: 1.3rem !important;
    }
    .MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline) {
      height: 1em !important;
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $primary;
      }
    }
  }
  .MuiSelect-select {
    min-height: 1em !important;
  }
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 3px;
    }
  }
  .MuiFormHelperText-root {
    font-size: 1.2rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #8c8c8c;
  }
}

// .breadcrumb-item {
//   a {
//     text-decoration: underline;
//   }
// }
.numberCell {
  text-align: right !important;
}
.stringCell {
  text-align: left !important;
}
.middCell {
  text-align: center !important;
}
.currencyCell {
  text-align: right !important;
  font-family: 'Open sans';
}
.k-grid-table {
  tr {
    &:hover {
      background: #feffb8 !important;
    }
  }
  .k-selected {
    td {
      background: #feffb8 !important;
    }
  }
  .k-grid-edit-row {
    background: #feffb8 !important;
  }
  .dirty-cell {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px;
    border-color: red transparent transparent red;
    margin: -0.45em 0 0 -0.6em;
    padding: 0;
    overflow: hidden;
    vertical-align: top;
  }
}
.alignMiddle {
  margin: auto !important;
  text-align: center !important;
}
.accordian-open {
  .accordion-header {
    background-color: #f1faff;
  }
}
.page-item {
  cursor: pointer;
  &:hover {
    background: #667f94;
  }
}
.up-down-arrow {
  cursor: pointer;
  &:hover {
    background-color: #667f94;
  }
}
.dragging {
  background-color: aqua;
}
.draggOver {
  background-color: blue;
}
.animation-move {
  cursor: move;
  animation: moveAnimation 3.5s infinite;
}

@keyframes moveAnimation {
  0%,
  100% {
    top: 5px;
  }
  50% {
    top: 0px;
  }
}
.drag-item {
  width: auto !important;
}

.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 9;
}
[data-title] {
  position: relative;
  cursor: help;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  top: -46px;
  padding: 10px;
  background: #000;
  color: #fff;
  font-size: 14px;
}
[data-title]:hover::after {
  content: '';
  position: absolute;
  top: -12px;
  left: 8px;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
}

.fixedTableHead {
  overflow-y: auto;
  max-height: 300px;

  thead th {
    position: sticky;
    top: 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  thead th .require {
    content: '*';
    color: red;
  }
}
.selected-card {
  border: 2px solid#054988 !important;

  background-clip: content-box !important;
}
.grid-item {
  background: #fff;

  display: flex;
  flex-direction: column;
  .card-header {
    .card-toolbar {
      display: none;
    }
  }

  &:hover {
    .card-header .card-toolbar {
      display: flex;
    }
    .toggle-panel-btn {
      display: block;
    }
  }
  .side-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease;
    overflow-y: auto;
    z-index: 1;

    .side-panel-content {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
    }
    .toggle-panel-close-btn {
      position: fixed;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      transition: background-color 0.3s ease;
    }
  }
  .toggle-panel-btn {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    transition: background-color 0.3s ease;
    display: none;
  }
}
.grid-item__graph {
  flex: 1;
}
.react-grid-item > .react-resizable-handle::after {
  border-right-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.grid-item__title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #181c32;
  display: flex;
  align-items: center;
  margin: 0.5rem;

  margin-left: 0;
}

.card-drag-area {
  &:hover {
    cursor: move;
  }
}

.sub-header {
  padding-bottom: 20px;
  background-color: #f8f9fa;
}

.stepper-header {
  display: flex;

  padding: 10;

  .stepper-item {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;

    .stepper-connector {
      content: '';
      position: absolute;
      height: 2px;
      background: #535353;
      width: calc(100% - 6rem);
      top: 50%; /* Adjust the vertical position to center the line */
      right: 0; /* Position the line to the right of the stepper-item-number */
      transform: translateX(50%); /* Move the line to the correct position */
    }
    .stepper-connector.complete {
      background: $primary-active;
      height: 5px;
    }
    .stepper-item-number {
      display: flex;
      align-items: center;
      border: 1px solid $primary;
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      justify-content: center;
      background-color: #fff;
      position: relative;
    }
    .stepper-item-line {
      left: 50%;
      top: 15px;
      width: calc(100% - 6rem);
      height: 2px;
    }
  }

  .stepper-item.active {
    .stepper-item-number {
      background-color: $primary-active;
    }
  }
  .stepper-item.completed {
    .stepper-item-number {
      background-color: $success;
      border: 0.5rem solid $primary-active;
    }
  }
  .stepper-item.remaining {
    &:hover {
      .stepper-item-number {
        border: 0.5rem solid $primary-active;
      }
    }
  }
}
.table-container {
  width: 100%;
  padding: 0.5rem;
  overflow: auto;
  .customTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    .main-table-body {
      border: 1px solid #eff2f5 !important;
      tr {
        border-bottom: 1px solid #eff2f5 !important;
        border-top: 1px solid #eff2f5 !important;
      }
    }

    th,
    td {
      padding: 8px;
      white-space: normal;
    }

    // td[colspan='100%'] {
    //   padding: 0;
    // }
    .child-row {
      background-color: #f5f8fa;
    }
    .child-table {
      table-layout: fixed;
      width: 100%;
      margin-top: 10px;
      box-shadow: 0 0 5px #e7e7e7;
    }

    .child-table td:first-child {
      padding-left: 0.8rem;
    }
  }
}

.accordion-main-container {
  list-style: none;
  padding: 0;
  .custom-accordion-item {
    width: 100%;
    padding-top: 1rem;

    .accordion-item-heading {
      border: 1px solid gray;
      padding: 0.8rem 0.5rem;

      // .accordion-item-heading-title {

      // }
    }
    .accordion-item-content {
      height: 0;
      overflow: hidden;
      transition: all ease-out 0.3s;
    }
    .accordion-item-content.active {
      height: auto;
      margin-top: 1rem;
      padding: 1.2rem 1rem;
    }
  }
  .custom-accordion-item.sub-accordion-item {
    border-top: 1px solid gray;

    .sub-accordion-item-heading {
      border: none;
      .accordion-item-number {
        display: flex;
        align-items: center;
        border: 1px solid gray;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        justify-content: center;
        background-color: #fff;
        position: relative;
        margin-right: 1rem;
      }
    }
  }
  .custom-accordion-item.sub-accordion-item:last-child {
    border-bottom: 1px solid gray;
  }
}

.table-responsive {
  padding: 0.5rem;

  table {
    border-collapse: collapse;
    table-layout: inherit;
  }
  thead {
    .mainTableHeaderRow {
      th {
        display: table-cell;
        vertical-align: middle;
        color: #666666;
        text-transform: capitalize !important;
        font-size: 1.3rem;
        font-weight: 500;
        .headContent {
          display: flex !important;
          align-items: center !important;
          &:hover {
            .columnMenu {
              display: flex !important;
            }
          }
        }
      }
    }
  }
  tbody {
    border: 1px solid #cccccc !important;
    font-size: 1.2rem;
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
    tr {
      &:hover {
        background-color: #feffb8 !important;
      }
    }
  }
  tfoot {
    border: 1px solid #cccccc !important;
  }
  th,
  td {
    padding: 8px;
    white-space: normal;
    border: none !important;
    white-space: 'break-spaces';
    max-width: 320px; /* Set max-width for responsive behavior */
    overflow: hidden; /* Overflow behavior */
    text-overflow: ellipsis; /* Truncate text */
  }
  tr.selectedRow {
    border-left: 0.5rem solid $primary;
  }
  .innerTable {
    td {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .collapsableTr {
    &:hover {
      background: white !important;
    }
  }
  .sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    border-right: 4px solid #cccccc !important;
  }

  .firstColSticky {
    thead th:first-child {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 50;
      background: #fff;
    }
    tbody tr td:first-child,
    tfoot tr td:first-child {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 40;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: #cccccc;
      }
    }
    tbody tr td:first-child {
      background: inherit;
      top: auto;
    }
    tfoot tr td:first-child {
      background: #ffffff;
      top: auto;
    }
  }

  .twoColSticky {
    thead th:first-child {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 50;
      background: #fff;
    }
    tbody tr td:first-child,
    tfoot tr td:first-child {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 40;
    }
    thead th:nth-child(2) {
      position: sticky;
      top: 0;
      left: 150px;
      z-index: 50;
      background: #fff;
    }
    tbody tr td:nth-child(2),
    tfoot tr td:nth-child(2) {
      position: sticky;
      top: 0;
      left: 150px;
      z-index: 40;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 4px;
        height: 100%;
        background: #cccccc;
      }
    }
    tbody tr td:first-child,
    tfoot tr td:first-child,
    tbody tr td:nth-child(2),
    tfoot tr td:nth-child(2) {
      background: inherit;
      top: auto;
    }
  }
}

.fixed-table-layout {
  table-layout: fixed !important;

  th,
  td {
    width: 160px !important;
  }
  th span {
    white-space: break-spaces !important;
    text-overflow: ellipsis !important;
  }
}
.dividedFooter {
  tfoot {
    td {
      flex-grow: 1;
      position: relative;
    }
    td::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      border-top: 1px solid #cccccc;
      transform: translateY(-50%);
    }
  }
}
.virtualTableContainer {
  border: 0px 1px 1px 1px solid #e4e4e4;
  padding: 0 0.5rem;
  overflow-x: auto;
  table {
    border-collapse: collapse;
    width: 100%; /* Ensure table takes full width */
    table-layout: inherit;
    tbody,
    thead {
      th,
      td {
        border: none !important;
        white-space: break-spaces;
        max-width: 320px; /* Set max-width for responsive behavior */
        overflow: hidden; /* Overflow behavior */
        text-overflow: ellipsis; /* Truncate text */
      }
    }

    th,
    td {
      min-width: 100px; /* Example of setting a minimum width */
    }

    th {
      background-color: #fff;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 2;
      .mainTableHeaderRow {
        th {
          display: table-cell;
          vertical-align: middle;
          color: #666666;
          text-transform: capitalize !important;
          font-size: 1.3rem;
          font-weight: 500;
          .headContent {
            display: flex !important;
            align-items: center !important;
            &:hover {
              .columnMenu {
                display: flex !important;
              }
            }
          }
        }
      }
    }

    tbody {
      font-size: 1.2rem;

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }
    }

    tfoot {
      background-color: #fff;
      td {
        background-color: #fff;
      }
    }

    .stickyFooter {
      position: sticky;
      bottom: 0;
      z-index: 2;
      background-color: inherit;
      td {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 1; /* Adjust the z-index accordingly */
        background-color: inherit;
      }
    }
  }

  .firstColSticky {
    thead,
    tfoot {
      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        z-index: 3; /* Adjust the z-index accordingly */
        background-color: #fff;
      }
    }

    tbody {
      tr {
        td:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
          background-color: inherit;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 2px;
            height: 100%;
            background: #cccccc;
          }
        }
      }
    }
  }
}

.fix-layout {
  table-layout: fixed;
}
.linkedTitle {
  color: $primary !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important ;
  }
}
.iconColumn {
  width: 5rem;
}
.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
.cursor-noClick {
  pointer-events: none;
  &:hover {
    cursor: not-allowed;
  }
}
.widget-card-container {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Distributes items evenly along the main axis */
  /* Prevents wrapping to ensure all items stay on one line */
  gap: 1%;
  .widgetCard_item {
    flex: 1 1 20%; /* Each item takes up 20% of the container width (5 items * 20% = 100%) */
    box-sizing: border-box; /* Include padding and borders in the width calculation */

    .widgetCard {
      border: 1px solid gray;
      border-top-left-radius: 5%;
      border-top-right-radius: 5%;
      display: flex;
      flex-direction: column;
      text-align: center;

      height: 100%; /* Stretch the card vertically */

      &:hover {
        .widgetCard_title {
          text-decoration: underline;
        }
        .widgetCard_border {
          height: 0.2rem;
        }
      }
      .widgetCard_content {
        .widgetCard_count {
          display: flex;
          align-items: baseline;
          justify-content: center;
        }
      }

      .widgetCard_duration {
        position: relative;
        display: flex;
        margin-bottom: 2rem !important;
        justify-content: center;
      }
      .widgetCard_border {
        margin-top: auto;
        height: 0.5rem;
      }
    }
  }
  .info_icon {
    position: absolute;
    bottom: 1em;
    left: 1em;

    z-index: 2;
  }
}
.error-div {
  position: relative;

  .error-indicator {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    z-index: 2;
  }

  .error-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Expand to full width of error-div */
    height: 100%; /* Expand to full height of error-div */
    background-color: $danger;
    color: white;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    white-space: break-spaces;
    overflow-y: auto;
    font-size: 1.2rem;
    font-weight: 600;
    transform: scale(1); /* Start with no scale */
    transform-origin: center center;
    transition: transform 0.5s ease; /* Add a smooth transition effect for the content */
    opacity: 1; /* Start with no opacity */
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .error-indicator:hover + .error-content {
    transform: scale(1); /* Expand the content to fit the widgetCard size on hover */
    opacity: 1; /* Show the content on hover */
    z-index: 1;
    display: flex !important;
    flex-direction: column !important;
  }
}
.badge {
  border-radius: 15px;
}
.menu-btn {
  &:hover {
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }
}
.MuiPaper-root {
  .MuiList-root {
    padding: 0 !important;
  }
}

.dropDownMenuItem {
  border-bottom: 2px solid #cccccc !important;
  border-left: 5px solid #fff !important;
}
.dropDownMenuItem.selected {
  border-left: 5px solid #29b46e !important;
  background: #fff !important;
}

.page-btn-back {
  border-top-right-radius: none;
  border-bottom-right-radius: none;
  border-bottom-left-radius: 50% 50px;
  border-top-left-radius: 50% 50px;
}
.page-btn-next {
  border-top-right-radius: 50% 50px;
  border-bottom-right-radius: 50% 50px;
  border-bottom-left-radius: none;
  border-top-left-radius: none;
}
.page-item-btn {
  border-radius: 0;
  padding-left: 0.7%;
  padding-right: 0.7%;
}
.imageIcon {
  height: auto;
  width: 50px;
}
// .side-panel {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 50%;
//   height: 100%;
//   background-color: #fff;
//   transition: left 0.3s ease;
//   overflow-y: auto;
//   z-index: 2;

//   .toggle-panel-close-btn {
//     position: absolute;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//     z-index: 1;
//     transition: background-color 0.3s ease;
//   }
// }
.sortIcon svg {
  height: 0.7rem !important;
}
.toastBorder {
  border-width: 2px 2px 2px 10px;
  border-style: solid;
}
.successToast {
  border-color: $success-active;
  .Toastify__progress-bar {
    background: $success-active;
  }
}
.dangerToast {
  border-color: $danger-active;
  .Toastify__progress-bar {
    background: $danger-active;
  }
}
.primaryToast {
  border-color: $primary-active;
  .Toastify__progress-bar {
    background: $primary-active;
  }
}
.warningToast {
  border-color: $warning-active;
  .Toastify__progress-bar {
    background: $warning-active;
  }
}
.roundBorderChips {
  border-radius: 25px;
  text-align: center;
  display: block;
  padding: 1rem;
  border: 2px solid;
}
.rotate-item-90 {
  transform: rotate(90deg);
}
.rotate-item-180 {
  transform: rotate(180deg);
}
.rotate-item-270 {
  transform: rotate(180deg);
}
//input field height
// .btn {
//   height: $input-field-height !important;
// }
// .MuiOutlinedInput-root {
//   height: $input-field-height !important;
// }
.customInputs {
  height: $input-field-height !important;
}
// .MuiSvgIcon-root {
//   height: 2em !important;
//   width: 2em !important;
// }
.placeholder {
  position: absolute;
  background-color: white;
  border-radius: 3px;
  border: dashed 1px blue;
  background-color: white;
}
.recharts-pie-sector:hover {
  transform: translateY(-5px);
  transition: transform 0.2s ease-in-out;
}
.chart-container {
  width: 100%; /* Set your maximum width */
  max-height: 500px; /* Set your maximum height */
  margin: auto; /* Center the chart horizontally */
  height: 500px;
}
#boxThis {
  background-color: #80c294;

  &.box {
    margin-top: 100px;
    position: fixed;
    top: 0;
    z-index: 9999;
    border-radius: 0 0 10px 10px;
    overflow-x: auto;
  }
}
@media (min-width: 1400px) {
  // #kt_content {
  //   max-width: 1366px;
  //   margin: 0 auto;
  //   width: 100%;
  // }
  .fix_width_container {
    max-width: 1366px;
    margin: 0 auto;
    width: 100%;
    transition: max-width 0.5s ease-in-out; // Added transition for width change
    position: relative;
  }
  .normal_container {
    max-width: 100%; // Ensure the width is set for the transition
    transition: max-width 0.5s ease-in-out; // Added transition for width change
    position: relative;
  }
}

@media (max-width: 1366px) {
  .fix-layout {
    table-layout: auto;
  }
  .innerTable {
    table-layout: fixed !important;
  }
  .rightHeaderMenu {
    .menu {
      display: flex;
      flex-direction: row;
      .menu-item {
        .menu-title {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .widget-card-container {
    justify-content: flex-start;
    flex-wrap: wrap;
    .widgetCard_item {
      flex: 0 0 30%;
      padding: 1%;
    }
  }
}

@media (max-width: 576px) {
  .d-xs-none {
    display: none !important;
  }
  .widget-card-container {
    flex-wrap: wrap;
    .widgetCard_item {
      flex: 0 0 100%;
      padding: 2% 0;
    }
  }
}
