//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: if(isDarkMode(), #1469b3, #1469b3);
$primary-active: if(isDarkMode(), #054988, #054988);
$primary-light: if(isDarkMode(), #212e48, #aec2cc);
$primary-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #0bb783, #008000);
$success-active: if(isDarkMode(), #04aa77, #47be7d);
$success-light: if(isDarkMode(), #1c3238, #e5feeb);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #8950fc, #14b38d);
$info-active: if(isDarkMode(), #7337ee, #049a77);
$info-light: if(isDarkMode(), #2f264f, #f8f5ff);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #de2417);
$danger-active: if(isDarkMode(), #ee2d41, #d9214e);
$danger-light: if(isDarkMode(), #3a2434, #debac5);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #f7990c);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

$table-header-color: #666666;

$dark: #000000;
$gray-700: #333333;
$gray-500: #595959;
$body-color: #000000;

//scale ratio
$theme-scale-size: (
  '1': 110%,
  '2': 120%,
  '3': 130%,
  '4': 140%,
) !default; // Custom variable

$input-field-height: 4rem;
// Forms
//$form-label-font-weight:                          600;
//$form-label-font-size:                            1.05rem;

//$input-btn-font-weight                            500;
//$input-btn-font-size:                             1rem;
//$input-btn-font-size-sm:                          0.95rem;
//$input-btn-font-size-lg:                          1.1rem;

// Buttons
//$btn-font-weight:                                 $input-btn-font-weight;
//$btn-font-size:                                   $input-btn-font-size;
//$btn-font-size-sm:                                $input-btn-font-size-sm;
//$btn-font-size-lg:                                $input-btn-font-size-lg;

// Inputs
//$input-font-weight:                               $input-btn-font-weight;
//$input-font-size:                                 $input-btn-font-size;
//$input-font-size-sm:                              $input-btn-font-size-sm;
//$input-font-size-lg:                              $input-btn-font-size-lg;
