//
// Menu
//

// Aside menu
.aside-menuTitle {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.aside-menu {
  .menu {
    // Link padding x
    @include menu-link-padding-x(get($aside-config, padding-x));

    // Menu indention
    @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
  }

  // Item
  .active {
    border-color: #14b38d !important;
  }
  .disabled {
    &:hover {
      border-color: #054988 !important;
    }
  }
  .menu-item {
    padding: 0;
    border-left: 5px solid #054988;
    &:hover {
      border-color: #14b38d;
      .menu-title {
        text-decoration: underline;
      }
    }
    .active {
      border-color: #14b38d;
    }
    // Menu title
    .menu-title {
      font-size: 1.3rem;
      font-weight: 600;
      color: #ffffff !important;
    }

    // Menu Link
    .menu-link {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      gap: 3%;
    }

    // Icon
    .menu-icon {
      justify-content: flex-start;
    }
  }
}

.aside-menu-collapsed {
  .menu {
    // Link padding x
    @include menu-link-padding-x(get($aside-config, padding-x));

    // Menu indention
    @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
  }

  // Item
  .active {
    border-color: #14b38d !important;
  }
  .disabled {
    &:hover {
      border-color: #054988 !important;
    }
  }
  .menu-item {
    padding: 0;
    border-left: 5px solid #054988;
    &:hover {
      border-color: #14b38d;
      .menu-title {
        text-decoration: underline;
      }
    }
    .active {
      border-color: #14b38d;
    }

    // Menu title
    .menu-title {
      font-size: 1rem;
      font-weight: 400;
      color: #ffffff !important;
      padding: 0%;
      display: none;
    }

    // Menu Link
    .menu-link {
      display: flex;
      flex-direction: column;
      padding: 0.75rem 0 0.75rem 0 !important;
    }

    // Icon
    .menu-icon {
      justify-content: center;
    }
  }
  .menu-sub-lg-down-accordion {
    .active {
      border-color: #14b38d !important;
    }
    .disabled {
      &:hover {
        border-color: #054988 !important;
      }
    }
    .menu-item {
      padding: 0;
      border-left: 5px solid #054988;
      &:hover {
        border-color: #14b38d;
        .menu-title {
          text-decoration: underline;
        }
      }
      .active {
        border-color: #14b38d;
      }
      // Menu title
      .menu-title {
        font-size: 1rem;
        font-weight: 400;
        color: #000000 !important;
        padding: 0%;
      }

      // Menu Link
      .menu-link {
        display: flex;
        flex-direction: row;
        padding: 0.75rem 0 0.75rem 0 !important;
      }

      // Icon
      .menu-icon {
        justify-content: center;
      }
    }
  }
}
