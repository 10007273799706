html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size;
  font-weight: $font-weight-base;
  font-family: 'Montserrat', 'sans-serif' !important;

  @include media-breakpoint-up(xxl) {
    font-size: $root-font-size-lg !important;
  }
  @include media-breakpoint-down(xxl) {
    font-size: $root-font-size-md !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: $root-font-size-sm !important;
  }
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}
