@import '../../../_metronic/assets/sass/components/variables.custom';

//Headings
.heading1 {
  font-size: 3.4rem;
  line-height: 3.6rem;
  font-weight: 600;
}
.heading2 {
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 500;
}
.heading3 {
  font-size: 2.2rem;
  line-height: 2.4rem;
  font-weight: 600;
}
.heading4 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.heading5 {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 700;
}

//body1 regular
.body1-regular {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
}
.body1-medium {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.body1-semibold {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 600;
}
.body1-bold {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
}

//body2 regular
.body2-regular {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
}
.body2-medium {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.body2-semibold {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
}
.body2-bold {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
}

//body3
.body3-regular {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
}
.body3-medium {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
}
.body3-semibold {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
}
.body3-bold {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 700;
}

//label
.label-regular {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 400;
}
.label-medium {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 500;
}
.label-semibold {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
}
.label-bold {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 700;
}

//link1
.link1-regular {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
}
.link1-semibold {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
}

//link2
.link2-regular {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
}
.link2-semibold {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
}
